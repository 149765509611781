<template>
	<div>
		<page-header :title="title"></page-header>
		<form @submit.prevent="gravar">
			<div class="modal-body">
				<div class="form-horizontal">
					<vue-element-loading :active="isActive" spinner="bar-fade-scale" color="#428BCA"></vue-element-loading>
					<div class="form-group"><label class="col-sm-3 control-label no-padding-right" for="cpf"> CPF</label>
						<div class="col-sm-9">
							<the-mask class="col-xs-10 col-sm-3 cpf" type="text" name="usuario" :mask="['###.###.###-##']"
								v-model="item.cpf" placeholder="CPF" required="" :disabled="$route.params.id"></the-mask>
						</div>
					</div>
					<div class="form-group"><label class="col-sm-3 control-label no-padding-right" for="senha"> Senha</label>
						<div class="col-sm-9"><input class="col-xs-10 col-sm-2" type="password" v-model="item.password"
								placeholder="Senha" /></div>
					</div>
					<div class="form-group"><label class="col-sm-3 control-label no-padding-right" for="senha2"> Repetir
							Senha</label>
						<div class="col-sm-9"><input class="col-xs-10 col-sm-2" type="password" v-model="item.senha2"
								placeholder="Repetir Senha" /></div>
					</div>
					<div class="form-group"><label class="col-sm-3 control-label no-padding-right" for="email"> E-mail</label>
						<div class="col-sm-9"><input class="col-xs-10 col-sm-8" type="email" v-model="item.email"
								placeholder="E-mail" required="" /></div>
					</div>
					<div class="form-group"><label class="col-sm-3 control-label no-padding-right" for="nome"> Nome</label>
						<div class="col-sm-9"><input class="col-xs-10 col-sm-8" type="text" v-model="item.name" placeholder="Nome"
								required="" /></div>
					</div>
					<div class="form-group">
						<select-form v-model="item.id_grupo" name="Perfil" url="/perfil" id="id" titulo="titulo" :session="true"
							layout="true"></select-form>
					</div>
					<div>
						<vue-element-loading :active="isActivePerfil" spinner="bar-fade-scale" color="#428BCA"></vue-element-loading>
						<div class="form-group"><label class="col-sm-3 control-label no-padding-right" for="perfil"> Permissão</label>
							<div class="col-sm-9">
								<ul v-for="(m, i) in menu" :Key="i">
									<li v-if="menu.length">
										<div class="checkbox">
											<label>
												<input class="ace" type="checkbox" v-model="m.visualizar" />
												<span class="lbl">{{ m.titulo }}</span>
											</label>
										</div>
										<ul v-for="(sub, ks) in m.submenu" :key="ks">
											<li>
												<div class="checkbox">
													<label>
														<input class="ace" type="checkbox" v-model="sub.visualizar" />
														<span class="lbl">{{ sub.titulo }}</span>
													</label>
												</div>	
												<ul v-for="(act, ia) in sub.actions" :key="ia">
													<li>
														<div class="checkbox">
															<label>
																<input class="ace" type="checkbox" v-model="act.value" />
																<span class="lbl">{{ act.name }}</span>
															</label>
														</div>
													</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="form-group">
						<select-form v-model="item.bloquear" :value="item.bloquear" name="Ativo" url="/arquivos" id="id"
							titulo="nome" layout="true"></select-form>
					</div>
				</div>
				<div class="modal-footer"><button class="btn btn-primary" type="submit">Gravar</button></div>
			</div>
		</form>
	</div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueElementLoading from 'vue-element-loading'
import PageHeader from '@/components/PageHeader'
import { TheMask } from 'vue-the-mask'
import SelectForm from '@/components/form/SelectForm'
import MenuDefault from '@/modules/menu'
import req from '@/components/request'
import { sucesso, erro } from '@/components/Message'

export default {
	components: {
		VueElementLoading,
		PageHeader,
		TheMask,
		SelectForm,
	},
	watch: {
		'item.id_grupo'() {
			if(!this.item.id_grupo) {
				return this.menu = MenuDefault
			}
			return this.getPerfil()
		}
	},
	computed: {
		...mapGetters([
			'user'
		])
	},
	data() {
		return {
			title: 'Usuários',
			item: {
				name: '',
				email: '',
				password: '',
				senha2: '',
				cpf: '',
				bloquear: '',
				id_grupo: '',
			},
			menu: [],
			isActive: false,
			isActivePerfil: false,
		}
	},
	methods: {
		...mapActions([
			'getLogsTotal'
		]),
		async getUsuario() {
			const resp = await req(`/usuarios/${this.$route.params.id}`).catch(response => {
				console.log('error: ' + response)
				erro()
			})
			if (resp.length) {
				this.item = resp[0]
				this.isActive = false
				this.getMenu()
			}
			
		},
		async getPerfil() {
			this.isActivePerfil = true
			const resp = await this.$axios(`/perfil/${this.item.id_grupo}`)
			if (resp.data.id) {
				this.menu = JSON.parse(resp.data.menu)
			}
			this.isActivePerfil = false
		},
		async getMenu() {
			this.isActivePerfil = true
			const resp = await this.$axios('/menu', {
				params: { id: this.$route.params.id }
			})
			if (resp.data.length) {
				this.menu = JSON.parse(resp.data[0].menu)
			}
			this.isActivePerfil = false
		},
		getCheck(item) {
			if (item.submenu.length) {
				item.submenu.map(s => {
					s.visualizar = !item.visualizar
					if (s.actions.length) {
						s.actions.map(a => {
							a.value = !item.visualizar
							return a
						})
					}
					return s
				})
			}
		},
		getCheckSub(item) {
			if (item.actions.length) {
				item.actions.map(a => {
					a.value = !item.visualizar
					return a
				})
			}
		},
		async gravar() {
			this.isActive = true
			let acessos = this.menu ? JSON.stringify(this.menu) : null
			let itens = this.item
			itens.USER = this.user.id
			if (this.$route.params.id) {
				await req(`/usuarios/${this.$route.params.id}`, 'put', {
					itens,
					acessos
				}).catch(response => {
					console.log('error: ' + response)
					erro()
				})
				sucesso('Atualizado...')
				this.getLogsTotal(this.user.id)
				this.$router.push('/admin/usuarios')
			} else {
				await req('/usuarios', 'post', {
					itens,
					acessos
				}).catch(response => {
					console.log('error: ' + response)
					erro()
				})
				sucesso('Atualizado...')
				this.getLogsTotal(this.user.id)
				this.$router.push('/admin/usuarios')
			}
			this.isActive = false
		},
	},
	created() {
		this.menu = MenuDefault
		if (this.$route.params.id) {
			this.isActive = true
			this.getUsuario()
		}
	}
}
</script>

<style lang="stylus" scoped>
ul
	margin-left: 15px
	li 
		margin-left: 15px
		ul
			margin-left: 15px
			li 
				margin-left: 15px
</style>